import { render, staticRenderFns } from "./ListSchoolYear.vue?vue&type=template&id=3d014062&scoped=true&"
import script from "./ListSchoolYear.vue?vue&type=script&lang=js&"
export * from "./ListSchoolYear.vue?vue&type=script&lang=js&"
import style0 from "./ListSchoolYear.vue?vue&type=style&index=0&id=3d014062&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d014062",
  null
  
)

export default component.exports