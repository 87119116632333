<template>
  <div class="school-years-container">
    <div class="school-years-header">
      <h2>Années scolaires</h2>
      <button
        class="form"
        @click="createSchoolYear"
      >Créer une année scolaire</button>
    </div>
    <div class="status-container">
      <div class="group">
        <div class="status"></div>
        <span>Non active</span>
      </div>
      <div class="group">
        <div class="status verified"></div>
        <span>Active</span>
      </div>
    </div>
    <div
      class="school-year"
      v-for="(schoolYear, idx) in getSchoolYears"
      :key="idx"
    >
      <div class="infos">
        <span class="name">{{ schoolYear.name }}</span>
        <span class="dates">Du {{ convertTimestampToString(schoolYear.start.seconds) }} au {{ convertTimestampToString(schoolYear.end.seconds) }}</span>
      </div>
      <div class="actions">
        <font-awesome-icon :icon="['fas', 'file-export']" class="edit" @click.stop="exportData(schoolYear)" />
        <div class="status" :class="{ verified: schoolYear.active }" @click="toggleActive(schoolYear)"></div>
      </div>
    </div>
    <create-school-year ref="createSchoolYear" />
    <export ref="export" :school-year="exportSchoolYear" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CreateSchoolYear from '@/components/CreateSchoolYear.vue'
import Export from '@/components/Export.vue'

export default {
  name: 'ListSchoolYears',
  components: {
    CreateSchoolYear,
    Export,
  },
  computed: {
    ...mapGetters('schoolyears', ['getSchoolYears']),
  },
  data() {
    return {
      exportSchoolYear: null,
    }
  },
  methods: {
    ...mapActions('schoolyears', ['fetchSchoolYears', 'updateSchoolYearState']),
    createSchoolYear() {
      this.$refs.createSchoolYear.showModal()
    },
    exportData(schoolYear) {
      this.exportSchoolYear = schoolYear
      this.$nextTick(() => {
        this.$refs.export.showModal()
      })
    },
    toggleActive(schoolYear) {
      if (confirm(`Voulez vous vraiment ${schoolYear.active ? 'désactiver' : 'activer'} l'année scolaire ${schoolYear.uid.replace('-', '/')} ? ${schoolYear.active ? 'Aucune année scolaire ne sera active.' : 'Les autres années scolaires active seront désactivées.'}`)) {
        this.updateSchoolYearState({
          uid: schoolYear.uid,
          state: !schoolYear.active
        })
      }
    },
    pad(s) {
      return (s < 10) ? '0' + s : s
    },
    convertTimestampToString(date) {
      date = new Date(date * 1000)
      return [
        this.pad(date.getDate()),
        this.pad(date.getMonth()+1),
        date.getFullYear(),
      ].join('/')
    }
  },
  created() {
    this.fetchSchoolYears()
  }
}
</script>

<style lang="scss" scoped>
div.school-years-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  div.school-years-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;

    h2 {
      font-size: 20px;
    }

    button {
      margin: 0;
    }
  }

  div.school-year {
    width: 100%;
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 2px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    div.infos {
      display: flex;
      flex-direction: column;
      
      span.dates {
        font-weight: 300;
      }
    }

    div.actions {
      width: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg.edit {
        cursor: pointer;
      }
    }

    div.status {
      cursor: pointer;
    }
  }

  div.status-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    div.group {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-right: 15px;
      }

      div.status {
        margin-right: 5px;
      }
    }
  }

  div.status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: radial-gradient(lightsalmon 30%, transparent);
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff5b1f;
    }

    &.verified {
      background: radial-gradient(palegreen 30%, transparent);

      &:after {
        background-color: #2faa2f;
      }
    }
  }
}
</style>