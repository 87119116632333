<template>
  <div class="modal-container" :class="{ show }" v-if="form">
    <div class="modal create-edit-kid">
      <h2>Créer une année scolaire</h2>
      <font-awesome-icon :icon="['fas', 'times']" class="close" @click="hideModal" />
      <form v-if="form.step === 1" @submit.prevent="submitFirstStep">
        <div class="group">
          <label
            for="name"
            class="form"
          >Nom</label>
          <input
            type="text"
            class="form"
            id="name"
            :value="name"
            disabled
          />
        </div>
        <div class="grid">
          <div class="group">
            <label
              for="start"
              class="form"
            >Date de début</label>
            <input
              type="date"
              class="form"
              :class="{ error: form.inputsError.start }"
              id="start"
              placeholder="05/09/2021"
              v-model.trim="form.schoolYear.start"
              @change="form.inputsError.start = null"
            />
            <span class="form">{{ form.inputsError.start }}</span>
          </div>
          <div class="group">
            <label
              for="end"
              class="form"
            >Date de fin</label>
            <input
              type="date"
              class="form"
              :class="{ error: form.inputsError.end }"
              id="end"
              placeholder="05/09/2021"
              v-model.trim="form.schoolYear.end"
              @change="form.inputsError.end = null"
              :min="minimumEndDate"
              :disabled="!form.schoolYear.start"
            />
            <span class="form">{{ form.inputsError.end }}</span>
          </div>
        </div>

        <div class="grid">
          <div class="group">
            <label
              class="form"
              style="order: 0;"
            >Jours activés</label>
            <div
              class="checkbox"
              v-for="(day, idx) in form.schoolYear.enabledDays"
              :key="idx"
              :style="`order: ${idx === 0 ? 7 : idx};`"
            >
              <input
                type="checkbox"
                class="form"
                :id="`enabled-${idx}`"
                v-model="form.schoolYear.enabledDays[idx]"
                @change="form.inputsError.enabledDays = null"
              >
              <label class="form" :for="`enabled-${idx}`">{{ days[idx] }}</label>
            </div>
            <span class="form" style="order: 8;">{{ form.inputsError.enabledDays }}</span>
          </div>
          <div class="group">
            <label
              class="form"
            >Créneaux activés</label>
            <div
              class="checkbox"
              v-for="(day, idx) in form.schoolYear.slots"
              :key="idx"
            >
              <input
                type="checkbox"
                class="form"
                :id="`enabled-${idx}`"
                v-model="form.schoolYear.slots[idx].value"
              >
              <label class="form" :for="`enabled-${idx}`">{{ form.schoolYear.slots[idx].text }}</label>
            </div>
            <span class="form">{{ form.inputsError.slots }}</span>
            <div class="add-slot">
              <input
                type="text"
                class="form"
                placeholder="Matin / Midi / Soir"
                v-model.trim="form.tmpSlot"
                @keydown.enter.prevent="addSlot"
              />
              <font-awesome-icon :icon="['fas', 'plus-circle']" @click="addSlot" />
            </div>
          </div>
        </div>

        <button
          class="form full"
          type="submit"
        >Étape suivante</button>
        <button
          class="form full close"
          @click.prevent="hideModal"
        >Fermer</button>
      </form>
      <form v-if="form.step === 2" @submit.prevent="submitSecondStep">
        <table>
          <tr>
            <th>Jour</th>
            <th
              v-for="(slot, idx) in form.schoolYearSetup.general.slots"
              :key="`slot-${idx}`"
            >{{ slot.text }}</th>
          </tr>
          <tr
            v-for="(day, idx) in form.schoolYearSetup.days[form.weekIndex]"
            :key="`day-${form.weekIndex}-${idx}`"
          >
            <td>{{ day.date.toLocaleDateString('fr-FR', dayOptions) | capitalize }}</td>
            <td
              v-for="(slot, i) in day.slots"
              :key="`day-${form.weekIndex}-${idx}-slot-${i}`"
              class="hoverable"
              @click="slot.enabled = !slot.enabled"
            >
              <input
                type="checkbox"
                class="form"
                v-model="slot.enabled"
              >
            </td>
          </tr>
        </table>
        <div class="grid">
          <button
            class="form full white"
            @click.prevent="prevWeek"
            :disabled="form.weekIndex === 0"
          >Semaine précédente</button>
          <button
            class="form full white"
            @click.prevent="nextWeek"
            :disabled="form.weekIndex === form.schoolYearSetup.days.length - 1"
          >Semaine suivante</button>
        </div>
        <button
          class="form full"
          type="submit"
        >Créer l'année scolaire</button>
        <button
          class="form full close"
          @click.prevent="hideModal"
        >Fermer</button>
      </form>
    </div>
  </div>
</template>

<script>
import { add, format, isAfter, eachDayOfInterval } from 'date-fns'
import { mapActions } from 'vuex'

const defaultFormData = {
  schoolYear: {
    start: null,
    end: null,
    enabledDays: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    slots: [],
  },
  tmpSlot: null,
  inputsError: {
    start: null,
    end: null,
    slots: null,
    enabledDays: null,
  },
  step: 1,
  weekIndex: 0,
  schoolYearSetup: {
    general: null,
    days: null,
  },
}

export default {
  name: 'CreateSchoolYear',
  components: {
  },
  data() {
    return {
      show: false,
      form: null,
      days: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      dayOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
    }
  },
  computed: {
    name() {
      return `Année scolaire ${this.form.schoolYear.start?.split('-')[0] || ''}/${this.form.schoolYear.end?.split('-')[0] || ''}`
    },
    minimumEndDate() {
      return format(add(new Date(this.form.schoolYear.start), { days: 1 }), 'yyyy-MM-dd')
    },
  },
  methods: {
    ...mapActions('schoolyears', ['createSchoolYear']),
    showModal() {
      this.form = this.lodash.cloneDeep(defaultFormData)
      this.show = true
    },
    hideModal(e, skip = false) {
      if (skip || confirm('Voulez vous vraiment quitter ? Rien ne sera sauvegardé.')) {
        this.show = false
        this.form = null
      }
    },
    addSlot() {
      if (this.form.tmpSlot?.length > 0) {
        this.form.schoolYear.slots.push({
          text: this.form.tmpSlot,
          slug: this.form.tmpSlot.replace(/\s+/g, '-').toLowerCase(),
          value: true
        })
        this.form.tmpSlot = null
        this.form.inputsError.slots = null
      }
    },
    prevWeek() {
      if (this.form.weekIndex === 0) return
      this.form.weekIndex -= 1
    },
    nextWeek() {
      if (this.form.weekIndex === this.form.schoolYearSetup.days.length - 1) return
      this.form.weekIndex += 1
    },
    submitFirstStep() {
      let error = false

      Object.keys(this.form.schoolYear).forEach(key => {
        if (key === "name") return
        if (this.form.schoolYear[key] === null) {
          this.form.inputsError[key] = 'Ce champ est obligatoire'
          error = true
        } else if (key === 'enabledDays') {
          let oneDayEnabled = false
          this.form.schoolYear[key].forEach(day => {
            if (day) oneDayEnabled = true
          })
          if (!oneDayEnabled) {
            error = true
            this.form.inputsError[key] = 'Ce champ est obligatoire'
          }
        } else if (this.form.schoolYear[key].length < 1) {
          this.form.inputsError[key] = 'Ce champ est obligatoire'
          error = true
        }
      })

      if (!error &&
        !isAfter(new Date(this.form.schoolYear.end), new Date(this.form.schoolYear.start))) {
        error = true
        this.form.inputsError.end = 'La date de fin doit être après la date de début'
      }

      if (error) return

      let data = JSON.parse(JSON.stringify(this.form.schoolYear))

      data.name = this.name

      data.start = new Date(`${data.start} 00:00:00`)
      data.end = new Date(`${data.end} 00:00:00`)
      data.active = false

      data.slots = data.slots.filter(slot => {
        if (slot.value) {
          return true
        }
        return false
      }).map(slot => ({
        text: slot.text,
        slug: slot.slug
      }))

      const datesInterval = eachDayOfInterval({
        start: data.start, 
        end: data.end
      })

      let tmpDates = []
      let lastDay = 0
      let isSunday = false
      let dates = []

      data.enabledDays.forEach((day, idx) => {
        if (day && idx === 0) {
          isSunday = true
        }
        if (day && idx > lastDay) {
          lastDay = idx
        }
      })

      datesInterval.forEach((date, idx) => {
        const day = date.getDay();
        
        if (data.enabledDays[day]) {
          tmpDates.push({
            date,
            slots: data.slots.map(slot => ({
              name: slot.text,
              slug: slot.slug,
              enabled: true,
              kids: [],
            })),
          })
        }

        if (!isSunday && day === lastDay) {
          dates.push(tmpDates)
          tmpDates = []
        } else if (isSunday && day === 0) {
          dates.push(tmpDates)
          tmpDates = []
        } else if (idx === datesInterval.length - 1) {
          dates.push(tmpDates)
          tmpDates = []
        }
      })

      this.form.schoolYearSetup.general = data
      this.form.schoolYearSetup.days = dates
      this.form.step = 2
    },
    submitSecondStep() {
      const documentId = `${this.form.schoolYearSetup.general.start.getFullYear()}-${this.form.schoolYearSetup.general.end.getFullYear()}`
      this.createSchoolYear({
        docId: documentId,
        schoolYear: this.form.schoolYearSetup.general,
        schoolYearDays: this.form.schoolYearSetup.days
      })
      this.hideModal(null, true)
    }
  },
}
</script>

<style lang="scss" scoped>
svg.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;

  div.grid {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      min-width: 420px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px 20px;
    }
  }

  div.group {
    display: flex;
    flex-direction: column;
  }

  div.checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    label {
      margin: 0;
    }
  }

  div.add-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width: calc(100% - 30px) !important;
      margin-bottom: 0 !important;
    }

    svg {
      font-size: 25px;
      color: #3490dc;
      transition: color .2s ease;
      cursor: pointer;

      &:hover {
        color: #2779bd;
      }
    }
  }

  button {
    margin: 10px 0 0 !important;

    &.close {
      margin: 10px 0 0 !important;
      background-color: white;
      color: #3490dc;
      border: 1px solid #3490dc;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &.white {
      border: 1px solid #3490dc;
      background-color: white;
      color: #22292f;

      &:hover {
        background-color: #5fa5df;
      }
    }
  }

  table {
    display: block;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 115%;
    overflow: auto;
    width: auto;
    
    th {
      background-color: #509edd;
      color: white;
      font-weight: normal;
      padding: 20px 30px;
      text-align: center;
    }
    td {
      background-color: rgb(238, 238, 238);
      color: rgb(111, 111, 111);
      padding: 20px 30px;
      text-align: center;

      &.hoverable:hover {
        background-color: rgb(211, 211, 211);
      }
    }
  }
}
</style>